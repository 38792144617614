import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  setMinAndMaxDates(dateField, multiSelect) {
    var year = document.querySelector("input[name='year']").value
    var minDate = `${year}-12-01`
    var maxDate = `${Number(year) + 1}-01-31`

    dateField.set('minDate', minDate)
    dateField.set('maxDate', maxDate)

    if (!!multiSelect) {
      dateField.set('mode', 'multiple')
      dateField.set('conjunction', " / ")
    }
  }


  toggleOffDatesSelection(event) {
    const filterDatesOnPaidDayOffDates = function(start, endDate, enabledSpecificDates) {
      var payOffDatesSelection = document.getElementById('payOffDates')
      var workingDays = document.getElementById('contractorData').dataset.workDays.split(",")
      var selectDates = flatpickr(document.querySelector("input[name='selected_dates']"))
      let days = []

      payOffDatesSelection.classList.remove('d-none')
      if (!!start && !!endDate) {
        selectDates.set('minDate', start.selectedDates[0])
        selectDates.set('maxDate', endDate.selectedDates[0])
        selectDates.set('disable', [function(date) {
          let day = date.getDay().toString()

          if (workingDays.includes(day) && !days.includes(date.toDateString())) {
            days.push(date.toDateString())
          }

          return !(workingDays.includes(day))
        }])

      } else {
        selectDates.set('minDate', enabledSpecificDates[0])
        selectDates.set('maxDate', enabledSpecificDates[enabledSpecificDates.length - 1])
        days = enabledSpecificDates.filter(date => workingDays.includes(date.getDay().toString()));
        selectDates.set('enable', days)
      }

      selectDates.set('mode', 'multiple')
      selectDates.set('conjunction', " / ")
      document.getElementById('pay_all_label').innerHTML = `All Working Days (Total of ${days.length})`
    }


    var offDatesSelection = document.getElementById('offDates')
    var selectedOffDatesSelection = document.getElementById('selectedOffDates')
    var payOffDatesSelection = document.getElementById('payOffDates')

    payOffDatesSelection.classList.add('d-none')

    if (event.target.value == 'range') {
      flatpickr(document.querySelector("input[name='selected_off_dates']")).clear();

      offDatesSelection.classList.remove('d-none')
      selectedOffDatesSelection.classList.add('d-none')

      var start = flatpickr(document.querySelector("input[name='start_date']"))
      var endDate = flatpickr(document.querySelector("input[name='end_date']"))

      this.setMinAndMaxDates(start, false);
      this.setMinAndMaxDates(endDate, false);


      start.set('onChange', function(selectedDates, dateStr, instance) {
        if (selectedDates.length > 0  && endDate.selectedDates.length > 0) {
          filterDatesOnPaidDayOffDates(start, endDate, null)
        }
      });

      endDate.set('onChange', function(selectedDates, dateStr, instance) {
        if (selectedDates.length > 0  && start.selectedDates.length > 0) {
          filterDatesOnPaidDayOffDates(start, endDate, null)
        }
      });

    } else {
      flatpickr(document.querySelector("input[name='start_date']")).clear()
      flatpickr(document.querySelector("input[name='end_date']")).clear()

      offDatesSelection.classList.add('d-none')
      selectedOffDatesSelection.classList.remove('d-none')

      var dateField = flatpickr(document.querySelector("input[name='selected_off_dates']"))
      this.setMinAndMaxDates(dateField, true);

      dateField.set('onChange', function(selectedDates, dateStr, instance) {
        const sortedDates = selectedDates.sort((a,b) => a.getTime() - b.getTime());
        filterDatesOnPaidDayOffDates(null, null, sortedDates)
      });
    }
  }

  toggleBusinessClosureDates(event) {
    console.log(event.target)
    var offDatesOptionSelection = document.getElementById('offDatesOptionSelection')
    var offDatesSelection = document.getElementById('offDates')
    var payOffDatesSelection = document.getElementById('payOffDates')
    Array.from(document.querySelectorAll("input[name='off_dates_selection_option']")).forEach(field => field.required = true)

    if (event.target.value == 'yes') {
      offDatesOptionSelection.classList.remove('d-none')
    } else {
      offDatesOptionSelection.classList.add('d-none')
      offDatesSelection.classList.add('d-none')
      payOffDatesSelection.classList.add('d-none')
      document.getElementById('selectedDatesToPay').classList.add('d-none')
      document.getElementById('selectedOffDates').classList.add('d-none')
      Array.from(document.querySelectorAll("input[name='off_dates_selection_option']")).forEach(field => field.required = false)
    }
  }

  toggleSelectDatesPicker(event) {
    if (event.target.checked && event.target.value == 'pay_selected') {
      document.getElementById('selectedDatesToPay').classList.remove('d-none')
    } else {
      document.getElementById('selectedDatesToPay').classList.add('d-none')
    }
  }

  toggleCashBonusPayOption(event) {
    console.log(event.target.value)
    let transfeeOption = document.getElementById('cashBonusPayOptionContainer')
    let payrollReleased = document.getElementById('payrollInfo').dataset.decemberPayrollReleased

    if (payrollReleased == 'false') {
      return;
    }

    if (!!event.target.value ) {
      transfeeOption.classList.remove('d-none')
    } else {
      transfeeOption.classList.add('d-none')
    }
  }
}
