import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['selectField']

  connect() {
    const hasHelpText = !!this.selectFieldTarget.options[1].dataset.help

    if (!hasHelpText) {
      return new TomSelect(`#${this.selectFieldTarget.id}`, {
        maxOptions: 100
      });
    }

    const options = []
    Array.from(this.selectFieldTarget.options).forEach(opt => {
      options.push(
        {
          id: opt.value,
          title: opt.innerHTML,
          help: opt.dataset.help || ''
        }
      )
    })

    new TomSelect(`#${this.selectFieldTarget.id}`, {
      maxOptions: 100,
      valueField: 'id',
      searchField: 'title',
      options: options,
      render: {
        option: function(data, escape) {
          return '<div>' +
              '<span class="title">' + escape(data.title) + '</span>' +
              '<span class="help">' + escape(data.help) + '</span>' +
            '</div>';
        },
        item: function(data, escape) {
          return '<div title="' + escape(data.help) + '">' + escape(data.title) + '</div>';
        }
      }
    });
  }
}
