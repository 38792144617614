import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "emailField" ]

  connect() {
    this.emailFieldTarget.addEventListener('keyup', this.validateEmail.bind(this))
  }

  validateEmail() {
    var emailField = this.emailFieldTarget

    var emailValidationElement = emailField.parentElement.getElementsByClassName('emailValidation')[0]
    var validationMarkup = `
      <div class="d-flex align-items-center emailValidation w-100">
        <strong>Validating Email Inputs...</strong>
        <div class="spinner-border ms-auto" style="width: 1rem; height: 1rem" role="status" aria-hidden="true"></div>
      </div>
    `

    if (!!emailValidationElement) {
      emailValidationElement.innerHTML = validationMarkup
      emailValidationElement.classList.remove('d-none')
    } else {
      emailValidationElement = document.createElement('div')
      emailValidationElement.innerHTML = validationMarkup

      emailField.parentElement.append(emailValidationElement)
    }

    clearTimeout(emailField._timer);
    emailField._timer = setTimeout(()=>{
      var emailStrings = this.emailFieldTarget.value.split(",")
      var emailWithNameFormat = /[A-Z][a-zA-Z0-9._%+-]+\s[A-Z][a-zA-Z0-9._%+-]+\s<[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}>/
      var emailFormat = /^\S+@\S+\.\S+$/
      var invalidInputs = []

      Array.from(emailStrings).forEach(emailString => {
        let trimmed = emailString.trim().replace(/ +(?= )/g,'') // Remove any trailing or double whitespaces

        if (!emailWithNameFormat.test(trimmed) && !emailFormat.test(trimmed)) {
          invalidInputs.push(trimmed)
        }
      })

      if (invalidInputs.length > 0) {
        emailValidationElement.innerHTML = `
          <div class="d-flex align-items-center emailValidation">
            <strong class="text-danger">Invalid Email Formats Detected</strong>
          </div>
        `
      } else {
        emailValidationElement.innerHTML = `
          <div class="d-flex align-items-center emailValidation">
            <strong class="text-success">Valid Emails</strong>
          </div>
        `
      }
    }, 500);
  }
}
