import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';
import "slim-select/styles";

// Connects to data-controller="slim"
export default class extends Controller {
  static targets = ['field', 'bonusField']

  connect() {
    this.fieldTarget.classList.remove('form-select');

    new SlimSelect({
      select: this.fieldTarget,
      events: {
        afterChange: (newVal) => {
          if (newVal[0].value) {
            this.bonusFieldTarget.classList.remove('d-none');
          } else {
            this.bonusFieldTarget.classList.add('d-none');
          }
        }
      }
    })
  }
}
