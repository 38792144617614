import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "form", "generateButton", "regenerateButton" ]

    generatePayslip(e) {
        e.preventDefault()

        this.generateButtonTarget.classList.add('disabled')
        this.formTarget.action = this.generateButtonTarget.href
        this.formTarget.method = 'POST'
        this.formTarget.querySelector('input[name="authenticity_token"]').value = document.querySelector('meta[name="csrf-token"]').content
        this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true, detail: {} }))
    }

    regeneratePayslipEntries(e) {
        e.preventDefault()

        this.regenerateButtonTarget.classList.add('disabled')
        this.formTarget.action = this.regenerateButtonTarget.href
        this.formTarget.querySelector('input[name="authenticity_token"]').value = document.querySelector('meta[name="csrf-token"]').content
        this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true, detail: {} }))
    }

    update(e) {
        e.preventDefault()

        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        const url = e.currentTarget.href

        const data = {
            authenticity_token: authenticityToken,
            payslip: JSON.parse(e.currentTarget.dataset.params)
        }

        fetch(url, {
            credentials: 'include',
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token': authenticityToken
            },
            body: JSON.stringify(data),
        }).then(
            document.querySelector('#payslips').src = location.toString()
        ).then (
            Turbo.visit(location.toString())
        )
    }

    updateBatch(e) {
        e.preventDefault()

        var payslipIds = document.querySelector('#payslipIds').dataset.ids
        if (typeof payslipIds == 'string') {
            payslipIds = [ payslipIds ]
        }

        var actionConfirmed = confirm(`Are you sure you want to ${e.currentTarget.text.trim()} the selected payslips? (Total of ${payslipIds.length}) `);

        if (!actionConfirmed) { return }

        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        const url = '/admin/payslips/update-batch?process=' + e.currentTarget.dataset.process + '&payslip_ids=' + payslipIds.join(',')

        const data = {
            authenticity_token: authenticityToken
        }

        debugger;

        fetch(url, {
            credentials: 'include',
            method: 'PATCH',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token': authenticityToken
            },
            body: JSON.stringify(data)
        }).then(
            Turbo.visit(document.querySelector('#payslips').src)
        )
    }

    withCheckedAction(e) {
        e.preventDefault()

        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        const id = this.clientTarget.options[this.clientTarget.selectedIndex].value

        const url = '/admin/clients/' + id + '/contractors/invoiceable.json'

        fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token': authenticityToken
            }
        })
            .then(response => response.json())
            .then(function(data) {
                if (data['contractors'].length > 0) {
                    var html = '<option></option>'

                    data.contractors.forEach(function(contractor, index) {
                        html += '<option value="' + contractor.id + '">' + contractor.name + '</option>'
                    })

                    document.querySelector('select[name="invoice[contractor_id]"]').innerHTML = html
                }

                document.querySelector('select[name="invoice[currency_id]"]').value = data.currency_id
                document.querySelector('select[name="invoice[client_id]"]').classList.remove('disabled')
                document.querySelector('select[name="invoice[contractor_id]"]').classList.remove('disabled')
            })

        $.ajax({
            url: window.location.href,
            type: 'get',
            data: {
                Payslip_ids: Payslip_ids,
                process: ajax_action
            },
            async: false,
            beforeSend: function(xhr) {

                xhr.setRequestHeader('Accept', 'text/javascript');
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name=csrf-token]').attr('content'));
            },
            success: function(data) {
                fetchIDS();

            },
            error: function(jqXHR, textStatus, errorThrown) {
                response = JSON.parse(jqXHR.responseText);
                alert(response.message);
            }
        });
        hideLoader();
    }
}
