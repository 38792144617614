import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
    connect() {
        let options = {}
        options = { onEnd: this.end.bind(this) }
        //If its a form either new/edit, Pass no options. Also checking from <= 1 is because of the search form in the nav bar

        this.sortable = Sortable.create(this.element, options)
    }

    end(event) {
        console.log(event)
        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content

        let data = { position: event.newIndex + 1 }
        let id = event.item.dataset.id;
        let url = "/admin/sign_now_templates/" + id + "/change_position";

        fetch(url, {
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticityToken
          }
        }).catch(error => {
            alert(error);
        })

    }
}
