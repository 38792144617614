import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "company", "country", "currency", "gstFields", "gstFieldsTemplate", "paymentMode" ]

  connect() {
    if (this.hasCurrencyTarget) {
      this.toggleGst()
    }
    if (this.hasCompanyTarget && this.hasPaymentModeTarget) {
      this.updatePaymentIdentifierLabel()
    }
  }

    toggleGst(e) {
      const currency = this.currencyTarget.options[this.currencyTarget.selectedIndex].text

      if (currency == 'AUD') {
        const content = this.gstFieldsTemplateTarget.innerHTML
        this.gstFieldsTarget.innerHTML = content

        let country = document.getElementById('client_address_attributes_country_id')
        let chosenCountry = country.options[country.selectedIndex].text
        if (!location.href.includes('/edit')) {
          if (chosenCountry === 'Australia') {
            document.getElementById('client_gst').checked = true
          } else {
            document.getElementById('client_gst').checked = false
          }
        }
      } else {
        document.querySelector('#gst-fields-wrapper').innerHTML = ''
      }
    }

    onCountryIdChanged(event) {
      const that = this

      fetch('/admin/countries/' + this.countryTarget.options[this.countryTarget.selectedIndex].value + '.json')
        .then(response => response.json())
        .then(function(data) {
          let companies = document.querySelector('select[name="client[company_id]"]')

          Array.from(companies.options).forEach((option, i) => {
            if (option.value === String(data.default_company_id)) {
                companies.selectedIndex = i;
            }
          });

          fetch('/admin/companies/' + data.default_company_id + '.json')
            .then(response => response.json())
            .then(function(data) {
              let currencies = document.querySelector('select[name="client[currency_id]"]')

              Array.from(currencies.options).forEach((option, i) => {
                if (option.value === String(data.currency_id)) {
                  currencies.selectedIndex = i;
                  that.toggleGst()
                }
              });
            })
          .catch(error => {
              alert(error)
          })
        })
      .catch(error => {
          alert(error)
      })
    }

    updatePaymentIdentifierLabel() {
      const companyName = this.companyTarget.options[this.companyTarget.selectedIndex].text
      const paymentMode = this.paymentModeTarget.options[this.paymentModeTarget.value].text
      var label = 'Identifier'

      if (paymentMode === 'Credit Card' && companyName == 'Virtual Coworker Pty Ltd') {
        label = 'BPOINT Datavault Token'
      }

      document.querySelector('label[for="client_payment_identifier"]').innerHTML = label

      var paymentProfileIdentifierField = document.getElementById('client_payment_profile_identifier')
      var paymentIdentifierField = document.getElementById('client_payment_identifier')
      var cardCodeField = document.getElementById('client_card_code')
      var cardCodeEditContainer = document.getElementById('editCardCodeContainer')

      const authorizeNetFields = Array.from([paymentProfileIdentifierField, cardCodeField])

      const achDebitFields = Array.from(document.getElementsByClassName('achDebitFields'))
      const creditCardFields = Array.from(document.getElementsByClassName('creditCardFields'))

      switch(paymentMode) {
        case 'ACH Debit':
          achDebitFields.forEach(field => this.showAndRequireField(field))
          creditCardFields.forEach(field => this.hideAndResetField(field))

          break;
        case 'EFT':
          achDebitFields.concat(creditCardFields).forEach(field => this.hideAndResetField(field))

          break;
        case 'Ezidebit':
          achDebitFields.forEach(field => this.hideAndResetField(field))
          authorizeNetFields.forEach(field => this.hideAndResetField(field))
          this.showAndRequireField(paymentIdentifierField)

          break;
        default:
          achDebitFields.forEach(field => this.hideAndResetField(field))
          this.showAndRequireField(paymentIdentifierField)

          if (companyName == 'Virtual Coworker Inc') {
            authorizeNetFields.forEach(field => this.showAndRequireField(field))
            cardCodeEditContainer?.classList?.remove('d-none')
          } else {
            authorizeNetFields.forEach(field => this.hideAndResetField(field))
            cardCodeEditContainer?.classList?.add('d-none')
          }
          break;
      }
    }

    showAndRequireField(field) {
      field.parentElement.classList.remove('d-none')
    }

    hideAndResetField(field) {
      field.parentElement.classList.add('d-none')
      field.value = '';
    }

    copyClientNameAndEmail(event) {
      event.preventDefault()

      let fields = event.target.closest('.nested-fields')

      fields.querySelector('input[name$="[first_name]"]').value = document.querySelector('input[name="client[first_name]"]').value
      fields.querySelector('input[name$="[last_name]"]').value = document.querySelector('input[name="client[last_name]"]').value
      fields.querySelector('input[name$="[email]"]').value = document.querySelector('input[name="client[email]"]').value
    }

    downcaseEmailAddress(event) {
      event.target.value = event.target.value.toLowerCase();
    }

    editCardCode(event) {
      let btn = document.getElementById('client_card_code')

      btn.disabled = false;
      event.target.classList.add('d-none')
    }
}